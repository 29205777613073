import React, { FC } from "react";
import Button from "../common/button/button";
import Modal from "../common/modal/modal";
import ModalSubtitle from "../common/modal/modalSubtitle";
import ModalTitle from "../common/modal/modalTitle";
import CloseIcon from "@mui/icons-material/Close";
import { navigate } from "gatsby";

interface InitialSetupModalProps {
  onClose?: () => void;
  isOpen?: boolean;
}

const InitialSetupModal: FC<InitialSetupModalProps> = ({
  onClose = () => {},
  isOpen = false,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalTitle>
        <span>Wallet Connected</span>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </ModalTitle>
      <div className="flex flex-col space-y-24">
        <ModalSubtitle>
          You wallet is connected. We recommend setting up your profile next.
        </ModalSubtitle>
        <Button
          width="full"
          onClick={() => {
            onClose();
            navigate("/account/settings");
          }}
        >
          Set up profile
        </Button>

        <Button onClick={onClose} fill={false} width="full">
          Skip for now
        </Button>
      </div>
    </Modal>
  );
};

export default InitialSetupModal;
