// Frameworks
import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Layout Components
import "./styles/layout.css"; //Import css here
import Header from "./layout/header";
import Footer from "./layout/footer";
import InitialSetupModal from "./account/initialSetupModal";
import { AppState } from "../state";
import { useDispatch, useSelector } from "react-redux";
import { updateShowFirstLogin } from "../state/profile/actions";
import MobileTemp from "./common/mobileTemp/mobileTemp";
import { getChainName, useEthers } from "@usedapp/core";
import { CHAIN_ID, CHAIN_NAME } from "../constants";

interface LayoutProps {
  fullWidth?: boolean;
}

// Layout Wrapper
const Layout: FC<LayoutProps> = ({ fullWidth = false, children }) => {
  const dispatch = useDispatch();
  const showFirstLogin = useSelector<
    AppState,
    AppState["profile"]["showFirstLogin"]
  >((state) => state.profile.showFirstLogin);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { chainId } = useEthers();

  return (
    <div className="h-screen w-screen">
      <InitialSetupModal
        isOpen={showFirstLogin}
        onClose={() => dispatch(updateShowFirstLogin(false))}
      />
      <div className="h-full w-full overflow-y-auto no-scrollbar bg-dark-background">
        {chainId !== CHAIN_ID && (
          <div
            className="fixed w-full sm:top-56 md:top-0 bg-dark-red py-8"
            style={{ zIndex: 50 }}
          >
            <p className="text-center body1 px-2">{`Your wallet is connected to the ${getChainName(
              chainId
            )} network. Please switch your wallet to the ${CHAIN_NAME.charAt(
              0
            ).toUpperCase()}${CHAIN_NAME.slice(1)} network`}</p>
          </div>
        )}
        <div id="layoutHeader" className="min-h-full flex flex-col">
          {/* header */}
          <div className="flex justify-center w-full sticky top-0 z-40 md:relative shadow-mobile-nav md:shadow-none">
            <div
              className="w-full bg-dark-background p-16 md:p-32"
              style={{ maxWidth: "1440px" }}
            >
              <Header />
            </div>
          </div>
          {/* content */}
          <div
            className="flex flex-col flex-grow self-center w-full"
            style={{ maxWidth: fullWidth ? "" : "1440px" }}
          >
            {children}
          </div>
        </div>
        {/* footer */}
        <div className="flex justify-center w-full bg-black">
          <div
            className="flex bg-black p-16 md:p-32 lg:p-0 lg:px-32 lg:py-48 w-full"
            style={{ maxWidth: "1440px" }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
