import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { useAuthentication } from "../hooks/auth/useLogin";
import Api from "../services/api";
import Loading from "../components/loading/loading";
import { DateTime } from "luxon";
import { buildNotificationLink } from "../utils/utils";
import SEO from "../components/seo";
import { Message } from "../models/messages";
import { Link, navigate } from "gatsby";
import EmailIcon from "@mui/icons-material/Email";
import ClearAllIcon from "@mui/icons-material/ClearAll";

const NotificationsPage = () => {
  const { authLoading, user } = useAuthentication();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [notifications, setNotifications] = useState<Message[] | undefined>(
    undefined
  );
  const [markRead, setMarkRead] = useState<boolean>(false);

  useEffect(() => {
    if (!authLoading) {
      getMessages();
    }
  }, [authLoading]);

  const getMessages = () => {
    const api = new Api();
    api
      .getMessages()
      .then((response) => {
        setNotifications(response.data.messages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setMarkRead(false);
      });
  };

  useEffect(() => {
    if (markRead) {
      getMessages();
    }
  }, [markRead]);

  const markMessageRead = (messageId) => {
    const api = new Api();
    api
      .markRead(messageId)
      .then((resp) => {
        setMarkRead(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markAllRead = () => {
    const api = new Api();
    api
      .markAllRead()
      .then((resp) => {
        setMarkRead(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const convertDateString = (date) => {
    const convertDate = DateTime.fromISO(date);
    return convertDate.toFormat("h:mma MMM d, yyyy");
  };

  return (
    <Layout>
      <SEO title="Notifications" />
      {loading ? (
        <div className="flex flex-col mx-auto">
          <div className="flex flex-col mx-auto">
            <div className="mt-16 mx-auto">
              <Loading />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full flex flex-col mx-auto w-11/12 lg:w-2/3 xlg:w-3/5 my-16">
          <div className="flex flex-row pb-16 md:pb-32 space-x-32">
            <div className="flex flex-grow text-primary-light-grey">
              <p className="hidden md:block display1">Notifications</p>
              <h1 className="md:hidden">Notifications</h1>
            </div>
            <button
              className="hidden md:block text-dark-red btn-second"
              onClick={() => navigate("/account/settings")}
            >
              Get email notifications
            </button>
            <button
              className="md:hidden text-dark-white-64"
              onClick={() => navigate("/account/settings")}
            >
              <EmailIcon />
            </button>
            {notifications && notifications.length > 0 && (
              <>
                <button
                  className={`hidden md:block text-dark-red btn-second ${
                    notifications.filter((notice) => notice.unread === true)
                      .length === 0
                      ? "disabled-link opacity-50"
                      : ""
                  }`}
                  onClick={markAllRead}
                  disabled={
                    notifications.filter((notice) => notice.unread === true)
                      .length === 0
                  }
                >
                  Mark all as read
                </button>
                <button
                  className={`md:hidden text-dark-white-64 btn-second ${
                    notifications.filter((notice) => notice.unread === true)
                      .length === 0
                      ? "disabled-link opacity-50"
                      : ""
                  }`}
                  onClick={markAllRead}
                  disabled={
                    notifications.filter((notice) => notice.unread === true)
                      .length === 0
                  }
                >
                  <ClearAllIcon />
                </button>
              </>
            )}
          </div>

          <div
            className="flex flex-row w-full bg-dark-divider mb-24"
            style={{ height: "1px" }}
          />
          <>
            {!notifications ? (
              <div>
                <p className="body1 text-primary-light-grey">
                  Please connect your wallet to view notifications
                </p>
              </div>
            ) : (
              <>
                {notifications.length > 0 ? (
                  <div className="flex flex-col w-full md:w-4/5 mx-auto">
                    {notifications.map((notice) =>
                      notice.destination?.snapshot ? (
                        <a
                          href={buildNotificationLink(
                            notice.type,
                            notice.destination
                          )}
                          target="_blank"
                          key={`np-${notice.id}`}
                          onClick={() => markMessageRead(notice.id)}
                        >
                          <div className="flex flex-row w-full hover:bg-dark-notification-modal py-24">
                            <div className="flex flex-col flex-grow space-y-4 w-1/2 md:w-2/3">
                              <p
                                className="caption text-primary-light-grey"
                                style={{
                                  wordBreak: "normal",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {notice.title}
                              </p>
                              <p
                                className="body1 text-dark-white-64"
                                style={{
                                  wordBreak: "normal",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {notice.message}
                              </p>
                            </div>
                            <div className="flex flex-row w-1/2 md:w-1/3">
                              <div className="flex flex-row flex-grow justify-end">
                                {notice.unread ? (
                                  <div className="flex-flex-col mx-16 self-center">
                                    <div className="rounded-full h-12 w-12 horiz-gradient" />
                                  </div>
                                ) : (
                                  <div className="flex-flex-col mx-16 self-center">
                                    <div className="rounded-full h-12 w-12 bg-transparent" />
                                  </div>
                                )}
                              </div>

                              <div className="flex flex-row justify-end self-center">
                                <p className="body1 text-dark-white-64">
                                  {convertDateString(notice.createdAt)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-row w-full bg-dark-divider"
                            style={{ height: "1px" }}
                          />
                        </a>
                      ) : (
                        <Link
                          to={buildNotificationLink(
                            notice.type,
                            notice.destination
                          )}
                          onClick={() => markMessageRead(notice.id)}
                          key={`np-${notice.id}`}
                        >
                          <div className="flex flex-row hover:bg-dark-notification-modal py-24">
                            <div className="flex flex-col flex-grow space-y-4 w-1/2 md:w-2/3">
                              <p
                                className="caption text-primary-light-grey"
                                style={{
                                  wordBreak: "normal",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {notice.title}
                              </p>
                              <p
                                className="body1 text-dark-white-64"
                                style={{
                                  wordBreak: "normal",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {notice.message}
                              </p>
                            </div>

                            <div className="flex flex-row w-1/2 md:w-1/3">
                              <div className="flex flex-row flex-grow justify-end">
                                {notice.unread ? (
                                  <div className="flex flex-col mx-16 self-center">
                                    <div className="rounded-full h-12 w-12 horiz-gradient" />
                                  </div>
                                ) : (
                                  <div className="flex flex-col mx-16 self-center">
                                    <div className="rounded-full h-12 w-12 bg-transparent" />
                                  </div>
                                )}
                              </div>

                              <div className="flex flex-row justify-end self-center">
                                <p className="body1 text-dark-white-64">
                                  {convertDateString(notice.createdAt)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-row w-full bg-dark-divider"
                            style={{ height: "1px" }}
                          />
                        </Link>
                      )
                    )}
                  </div>
                ) : (
                  <div>
                    <p className="body1 text-primary-light-grey">
                      No notifications available
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      )}
    </Layout>
  );
};

export default NotificationsPage;
